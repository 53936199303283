import React, {useRef, useState} from "react";
import VideoPlayer from 'react-video-js-player';

function HeroBanner() {
  const player = useRef();
  const [muted, setMuted] = useState(true)
  return (
    <section id="home">
      <div className="container">
        <div className="home-text">
          <div className="section-text__title-big">
            <span className={'trade-mark'}>Matty.</span> Helps You Trade and Save.
          </div>
          <div className="section-text__subtitle">
          Cutting edge app utilizing Artificial Intelligence for dynamic (bot) management of a customized portfolio generated from your social media.
          </div>

          <a href={'https://apps.apple.com/us/app/matty/id1599765979'} target={'_blank'} className="download-btn">
            Get Matty!
          </a>
        </div>

        <div
          style={{paddingTop: 100}}
          className="section-image">
          <VideoPlayer
            muted={true}
            autoplay={true}
            controls={true}
            src={'landing_video.mp4'}
            width={640}
            poster={'images/video_poster.png'}
          />
        </div>
      </div>
    </section>
  );
}

export default HeroBanner;
