import React, {useEffect, useRef, useState} from "react";
import {Container, Spinner} from "react-bootstrap";
import {withRouter} from "react-router-dom";
import axios from "axios";
import {toast, ToastContainer} from 'react-toastify';
import { PayPalButtons } from "@paypal/react-paypal-js";
import 'react-toastify/dist/ReactToastify.css';

import Navbar from "./Navbar";
import {parse} from "qs";


function FundGoal(props) {
  const [goal, setGoal] = useState(null)
  const [user, setUser] = useState({})
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showCart, setShowCart] = useState(false)
  const [mode, setMode] = useState('test')
  const [amount, setAmount] = useState('')

  const [expiryDate, setExpiryDate] = useState({
    value: ""
  });
  const handleExpiryDate = date => {
    if (typeof date === 'string' || date instanceof String) {
      setExpiryDate({
        value: date
      });
    }
  };

  const [name, setName] = useState('')
  const formRef = useRef()
  const cardNumber = useRef()
  const cvvToken = useRef()
  const achToken = useRef()

  useEffect(() => {
   // setAccount("ifields_mattydeve359e708091e4672a513369181329", "mattydev", "1.0.0")
/*    let defaultStyle = {
      border: '1px solid #cccccc',
      'font-size': '14px',
      padding: '3px',
      width: '345px',
      height: '30px',
      paddingLeft: '10px',
      borderRadius: '5px'
    };
    setIfieldStyle('ach', defaultStyle);
    setIfieldStyle('card-number', defaultStyle);
    setIfieldStyle('cvv', defaultStyle);
    enableAutoFormatting('-');*/
  }, [])

  const createPayment = async () => {
    const card_number = cardNumber.current.value
    const cvv = cvvToken.current.value
    if (!card_number) {
      toast.error(`Please enter a valid card number`)
      setLoading(false)
      return
    }
    if (!cvv) {
      setLoading(false)
      toast.error(`Please enter a valid cvv number`)
      return
    }
    if (!expiryDate.value || !(typeof expiryDate.value === 'string' || expiryDate.value instanceof String) || !(expiryDate.value.split('/').length === 2)) {
      toast.error(`Please enter a valid expiry date`)
      setExpiryDate({value: ''})
      setLoading(false)
      return
    }
    if (!amount) {
      toast.error(`Please enter a valid amount`)
      setLoading(false)
      return
    }
    const response = await axios.post('https://us-central1-matty-628bf.cloudfunctions.net/api/make-payment', {
      payment_data: {
        card_number,
        exp_month: expiryDate.value.split('/')[0],
        exp_year: expiryDate.value.split('/')[1],
        cvv,
        amount
      }
    })
    if (response.status === 200 && response.data.ok) {
      completeFunding(response.data.amount)
    } else {
      toast.error(response.data.error)
      setLoading(false)
    }
  }
  const getOutstandingAmount = (goal) => {
    if (!goal)
      return 0
    const totalAmount = Number.parseFloat(goal.amount)
    const fundedAmount = goal.fundedAmount ? Number.parseFloat(goal.fundedAmount) : 0
    return totalAmount - fundedAmount
  }
  const getGoalData = async () => {
    try {
      const id = props.match.params.id
      const response = await axios.get(`https://us-central1-matty-628bf.cloudfunctions.net/api/fetchGoal?id=${id}`)
      if (response.status === 200 && response.data.ok) {
        setGoal(response.data.goal)
        const _goal = response.data.goal
        const _amount = getOutstandingAmount(_goal)
        setAmount(_amount)
        setUser(response.data.user)
      }
    } catch (e) {
      console.log(e)
    }
  }
  const completeFunding = async (amount) => {
    try {
      const id = props.match.params.id
      const response = await axios.post(`https://us-central1-matty-628bf.cloudfunctions.net/api/fundGoal`, JSON.stringify({
        id,
        amount,
        environment: mode,
      }), {headers: {'Content-Type': 'application/json'}})
      if (response.status === 200 && response.data.ok) {
        console.log(response.data)
      }
      setLoading(false)
      setShowCart(false)
      setSuccess(true)
    } catch (e) {
      setLoading(false)
      setShowCart(false)
      console.log(e)
    }
  }
  const fundNow = async () => {
    //setLoading(true)
    setShowCart(true)
    /*const stripe = await stripePromise;
    const response = await axios.post('https://us-central1-matty-628bf.cloudfunctions.net/api/create-checkout-session', JSON.stringify({
      amount: goal.amount,
      goal_id: props.match.params.id,
      environment: mode
    }), {
      headers: {'Content-Type': 'application/json'},
    });
    if (response.status === 200) {
      stripe.redirectToCheckout({
        sessionId: response.data.id,
      });
    }*/
  }
  useEffect(() => {
    setLoading(false)
    const params = parse(props.location.search, {ignoreQueryPrefix: true})
    if (params && params.env) {
      setMode(params.env)
    }
    console.log(params, mode)
    if (params && params.success) {
      setSuccess(true)
      completeFunding()
    } else
      getGoalData()
  }, [])
  const onFormSubmit = () => {
    setLoading(true)
    getTokens(
      (success) => {
        //formRef.current.submit()
        createPayment()
      },
      (error) => { //onError
        console.log(error)
        setLoading(false)
        toast.error(error)
        // submitBtn.disabled = false;
      },
      30000, //30 second timeout
    );
  }

  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: amount,
          },
        },
      ],
    });
  };
  const onApprove = (data, actions) => {
    return actions.order.capture()
      .then((res) => {
        setShowCart(false)
        setSuccess(true)
        completeFunding(amount)
      })
      .catch((err) => {
        console.log(err)
      })
  };


  return (
    <section id="commitments">
      <Navbar/>
      <div className="container" style={{minWidth: '90%'}}>
        <Container className="section-text commitment-section center-content center-content-vertical">
          <h1>
            <span className={'trade-mark'}>Matty</span>Goal Funding
          </h1>
          {!showCart && !success && goal && goal.funding !== 4 && <>
            <label style={{textAlign: 'center', marginTop: 50}}>
              {`${user.name} needs `}<h2
              style={{
                marginTop: 10,
                marginBottom: 8
              }}>{`$${getOutstandingAmount(goal)}`}</h2>{` funding for his Goal “${goal.title}”`}
            </label>
            <button
              onClick={fundNow}
              className="download-btn fundnow-btn">
              {loading && <Spinner animation="border" variant="light" style={{marginRight: 10}}/>}
              {`Contribute Now`}
            </button>

          </>}
          {!showCart && !success && goal && goal.funding === 4 &&
          <label style={{textAlign: 'center', marginTop: 50}}>
            {'This Goal is Already Funded'}
          </label>
          }
          {!showCart && !success && goal && goal.funding === 3 &&
          <label style={{textAlign: 'center', marginTop: 50}}>
            {'This Goal is Partially Funded'}
          </label>
          }
          {!showCart && success &&
          <>
            <img src="/images/check.png" className={'check-mark'} style={{marginTop: 100}}/>
            <label style={{textAlign: 'center', marginTop: 50}}>
              {'Congratulations! You have successfully funded the Goal.'}
            </label>
            <a href={'/'} style={{marginTop: 30}}>
              {'Go To Matty'}
            </a>
          </>
          }
          {
            showCart &&
            <div>
              <form ref={formRef} id="payment-form" className={'payment-form'}>
                {/*<input
                  className={'input-medium'}
                  onChange={({target}) => setName(target.value)}
                  id="name" name="xName" placeholder="Name" autoComplete="cc-name"/><br/>

                <input
                  data-ifields-id="ach-token"
                  name="xACH"
                  type="hidden"
                  ref={achToken}
                /><br/>
                <iframe
                  className='payment-iframe'
                  data-ifields-id="card-number"
                  data-ifields-placeholder="Card Number"
                  src="https://cdn.cardknox.com/ifields/2.12.2201.2101/ifield.htm"/>
                <input
                  ref={cardNumber}
                  data-ifields-id="card-number-token" name="xCardNum" type="hidden"/>
                <br/>
                <iframe
                  className='payment-iframe'
                  data-ifields-id="cvv"
                  data-ifields-placeholder="CVV"
                  src="https://cdn.cardknox.com/ifields/2.12.2201.2101/ifield.htm"/>
                <input
                  ref={cvvToken}
                  data-ifields-id="cvv-token" name="xCVV" type="hidden"/>
                <br/>*/}
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  {/*<ExpiryDateInput
                    label="Expiry Date"
                    onChange={date => handleExpiryDate(date)}
                    value={expiryDate}
                    disabled={false}
                  />*/}
                  {/*<input
                    onChange={({target}) => {
                      setAmount(target.value)
                    }}
                    value={amount}
                    className={'input-medium'}
                    style={{width: 180, marginLeft: 10}}
                    type="number"
                    placeholder={'amount'}
                    onKeyPress={(event) => {
                      const keyCode = event.keyCode || event.which;
                      const keyValue = String.fromCharCode(keyCode);
                      if (/\+|-/.test(keyValue))
                        event.preventDefault();
                    }}/>*/}
                </div>
                <div style={{maxHeight: 200, width: 350}}>
                  <label style={{color: '#2C3333', marginBottom: 2, marginLeft: 5}}>
                    {'Amount to Contribute:'}
                  </label>
                  <input
                    onChange={({target}) => {
                      setAmount(target.value)
                    }}
                    value={amount}
                    className={'input-medium'}
                    style={{width: '100%', marginLeft: 0, marginBottom: 30}}
                    type="number"
                    placeholder={'amount'}
                    onKeyPress={(event) => {
                      const keyCode = event.keyCode || event.which;
                      const keyValue = String.fromCharCode(keyCode);
                      if (/\+|-/.test(keyValue))
                        event.preventDefault();
                    }}/>
                  <PayPalButtons
                    style={{marginTop: 40}}
                    createOrder={(data, actions) => createOrder(data, actions)}
                    onApprove={(data, actions) => onApprove(data, actions)}
                  />
                </div>
                {/*<button
                  type={'button'}
                  style={{marginLeft: 30, marginTop: 50}}
                  onClick={onFormSubmit}
                  className="download-btn fundnow-btn">
                  {loading && <Spinner animation="border" variant="light" style={{marginRight: 10}}/>}
                  {`Checkout`}
                </button>*/}
              </form>
            </div>
          }
        </Container>
      </div>
      <ToastContainer position="top-center"/>
    </section>
  )
}

export default withRouter(FundGoal)
