import React, {useState} from "react";
import "../assets/footer.css";
import axios from "axios";
import {useAlert} from 'react-alert'

function Footer() {
  const alert = useAlert()
  const [email, setEmail] = useState('')

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const subscribeToNewsLetter = (email) => {
    if (!validateEmail(email)) {
      alert.error('Invalid email address')
      return
    }
    axios.post('https://us-central1-matty-live.cloudfunctions.net/api/subscribe_news_letter', {email}).then(
      (response) => {
        if (response.data.ok)
          alert.success('Successfully subscribed')
        else if (response.data.reason === 'duplicate') {
          alert.error('Already subscribed')
        } else
          alert.error('Failed to subscribe')
      },
      (error) => {
        console.log(error)
      }
    )
  }
  return (
    <footer>
      <div className="container" id={'contact-us'}>
        <div className="row">
          <div className="col-30">
            <div className="footer-text__title">Disclosure</div>
            <div className="footer-text__disclose">
              This website is a publication of Matty Investments, LLC. Information presented is
              believed to be factual and up-to-date, but we do not guarantee its accuracy and it should
              not be regarded as a complete analysis of the subjects discussed. All expressions of
              opinion reflect the judgment of the authors as of the date of publication and are subject to
              change.
            </div>
            <div className="footer-text__disclose">
              Information on this website does not involve the rendering of personalized investment
              advice. A professional adviser should be consulted before implementing any of the
              strategies presented. Content should not be construed as an offer to buy or sell, or a
              solicitation of any offer to buy or sell the securities mentioned herein.
            </div>

            <div className="footer-text__disclose">
              Hyperlinks on this website are provided as a convenience and we disclaim any
              responsibility for information, services or products found on websites linked hereto.
            </div>

            <div className="footer-text__disclose">
              Matty Investments, LLC is registered as an investment adviser and only transacts
              business in states where it is properly registered, or is excluded or exempted from
              registration requirements. Registration as an investment adviser does not constitute an
              endorsement of the firm by securities regulators nor does it indicate that the adviser has
              attained a particular level of skill or ability.
            </div>

            <div className="footer-text__disclose">
              All investment strategies have the potential for profit or loss. Different types of
              investments involve varying degrees of risk, and there can be no assurance that any
              specific investment or strategy will be suitable or profitable for a client&#39;s investment
              portfolio.
            </div>

          </div>
          <div className="col-30">
            <div className="footer-text__title">Quick links</div>
            <ul className="footer-list">
              <li>
                <a href="/privacy">Privacy Policy</a>
              </li>
              <li>
                <a href="https://drive.google.com/file/d/10V4u19BHCFIPqB7arkXVoLbHkc_RK6JE/view">CRS Disclosure</a>
              </li>
            </ul>
            <div className="footer-text__title"></div>
            <a className="fa fa-twitter" href="https://twitter.com/Matty_App" target={'_blank'}/>
            <a className="fa fa-facebook" href="https://facebook.com/mattyinvestments" target={'_blank'}/>
            <a className="fa fa-instagram" href="https://www.instagram.com/matty.app/" target={'_blank'}/>
            <div className="footer-text__title" style={{marginTop: 30, marginBottom: 20}}>Contact Us</div>
            <a href="mailto:support@matty.ai" target={'_blank'}>{'support@matty.ai'}</a>
            <div>{'+1 864-336-2715'}</div>
          </div>
          <div className="col-30">
            <div className="footer-text__title">Newsletter</div>
            <div className="footer-text__body">
              Get the latest news from Matty.
            </div>
            <div className="footer-input">
              <input
                onChange={(e) => setEmail(e.target.value)}
                type="text" name="email" placeholder="Email"/>
              <div
                className="footer-input__icon"
                style={{cursor: 'pointer'}}
                onClick={() => {
                  //alert('hello')
                  subscribeToNewsLetter(email)
                }}>
                <ion-icon name="paper-plane-outline"/>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          ©2023 Matty Investments, LLC
        </div>
      </div>
    </footer>
  );
}

export default Footer;
