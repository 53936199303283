import React from "react";
import Navbar from "./Navbar";

function Download({links, links2}) {
  return (
    <section id="download">
      <div className="container" style={{display: 'flex', justifyContent: 'center'}}>
        <div>
          <div className="section-text">
            <div className="section-text__title margin-bottom-small">
              Download<br/>the App Now!
            </div>
            <div className="section-text__body">
              <span className={'trade-mark'}>Matty</span> is available on
            </div>
          </div>
          <div className={'row'} style={{marginBottom: 120}}>
            <div className="section-image_small">
              <a href={'https://apps.apple.com/us/app/matty/id1599765979'} target={'_blank'}>
                <img
                  className={'image_small'}
                  src="./images/app-store-icon.svg"
                  alt="download"/>
              </a>
            </div>
            <div className="section-image_small">
            <a href={'https://play.google.com/store/apps/details?id=ai.matty'} target={'_blank'}>
              <img
                className={'image_small'}
                src="./images/google-play-img.png"
                alt="download"/>
                </a>
            </div>
          </div>
        </div>
        <div className="section-image">
          <img src="./images/matty-mobile.png" alt="app preview"/>
        </div>
      </div>


    </section>


  );
}

Download.defaultProps = {
  links: [
    {title: "How Matty Works", href: "#services"},
    {title: "Matty Difference", href: "#features"},
    {title: "Download", href: "#download"},
    {title: "Privacy", href: "/privacy"},
    {title: "CRV", href: "https://drive.google.com/file/d/10V4u19BHCFIPqB7arkXVoLbHkc_RK6JE/view"},
    {title: "Disclosures", href: "https://sites.google.com/matty.ai/matty-disclosures/home"},
  ],
  links2: [
    {title: "Terms & Conditions", href: "/"},
    {title: "Privacy", href: "/"},
    {title: "Legal", href: "#features"},
    {title: "Alpaca Broker", href: "#services"},
  ]
};
export default Download;
