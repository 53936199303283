import React from "react";

function Features() {
  return (
    <section id="features">
      <div className="container">
        <div className="section-image__small">
          <img src="./images/features.png" alt="features"/>
        </div>
        <div className="section-text">
          <div className="section-text__title">
            What Makes Matty Different?
          </div>

          <div className="row">
            <div className="feature-box col-50">
              <div className="section-text__title-small">Interest-Focused</div>
              <div className="section-text__body justify-text">
                Designed to create lifestyle(Patent Pending) investment portfolios, <span className={'trade-mark'}>Matty</span> analyzes
                your social media platforms in order to generate and suggest an array of companies that match your
                unique interests, lifestyle(Patent Pending) objectives, and favorite brands.
              </div>
            </div>

            <div className="feature-box col-50">
              <div className="section-text__title-small">Self-Driven</div>
              <div className="section-text__body justify-text ">
                Unlike many trading platforms that require constant manual monitoring, <span
                className={'trade-mark'}>Matty</span> is self-driven and uses precise algorithmic trading technology to
                automatically trade shares of your custom-made portfolios within specific sectors and industries. As a
                result, <span className={'trade-mark'}>Matty</span> strives to deliver top-tier investment profitability with minimal effort from your end. Simply set up your account and
                let <span className={'trade-mark'}>Matty</span> take care of the rest. Seriously, <span className={'trade-mark'}>Matty</span> was made for this.
              </div>
            </div>
          </div>

          <div className="row">
            <div className="feature-box col-50">
              <div className="section-text__title-small">Goal-Oriented</div>
              <div className="section-text__body justify-text">
                Whether you’re saving for college tuition, a first home, an
                exotic vacation, a gift for a loved one, or anything in between
                — what matters most to you, matters to <span className={'trade-mark'}>Matty</span>. The goal is to
                help you successfully set and reach your goals.
                Start saving now for the big things in life, the little things in
                life, and everything in between.
              </div>
            </div>

            <div className="feature-box col-50">
              <div className="section-text__title-small">Community-Centered</div>
              <div className="section-text__body justify-text">
                <span className={'trade-mark'}>Matty</span> is built on the power of community, and wants to
                make it easy to support and cheer on one another. With
                the touch of a button, you can share your specific goals
                with everyone in your social sphere, or contribute
                towards someone else’s goal.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;
