import React from "react";

function Screenshots() {
  return (
    <section id="screenshots" style={{ overflow: "hidden" }}>
      <div className="container">
        <div className="section-text">
          <div className="section-text__title">Investing Made Simple</div>
          <div className="section-text__body">
            Not a trading guru? No problem! <span className={'trade-mark'}>Matty</span> takes the mystery out of the stock market, and our goal is to make making money and saving money easy. .
            <br/><br/><span className={'trade-mark'}>Matty</span> delivers lifestyle-centric(Patent Pending) and goal-based investment advice. Our algorithms monitor each stock price within your portfolio and place the buy and sell orders when the defined conditions are met.
          </div>
        </div>
        <div className="section-carousel">
          <div className="section-carousel__images">
            <div className="section-carousel__image">
              <img src="./images/screen1.png" alt="app screenshot" />
            </div>

            <div className="section-carousel__image">
              <img src="./images/screen3.png" alt="app screenshot" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Screenshots;
