import React from "react";
import ReactDOM from "react-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import AlertTemplate from 'react-alert-template-basic'
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import App from "./App";
import Privacy from "./privacy";
import FundGoal from "./components/FundGoal";

const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.FADE
}

const rootElement = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
    <AlertProvider template={AlertTemplate} {...options}>
      <PayPalScriptProvider
        options={{
          "disable-funding": "credit",
          "client-id": "ARgTnF6PrSNZr5fewxU42iET1LQ8XDWXfEvLpLmf4CGW435TygWIQ23lMVgaOcHLgdH2jNWHp8CUeodw"
        }}>
        <Router>
          <Switch>
            <Route path="/" exact>
              <App/>
            </Route>
            <Route path="/privacy" exact>
              <Privacy/>
            </Route>
            <Route path="/fundgoal/:id" exact>
              <FundGoal/>
            </Route>
          </Switch>
        </Router>
      </PayPalScriptProvider>
    </AlertProvider>
  </React.StrictMode>,
  rootElement
);
