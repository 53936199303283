import React from "react";
import "./assets/styles.css";
import Navbar from "./components/Navbar";

import HeroBanner from "./components/HeroBanner";
import Features from "./components/Features";
import Services from "./components/Services";
import Screenshots from "./components/Screenshots";
import Footer from "./components/Footer";
import Download from "./components/Download";
import Commitment from "./components/Commitment";
import {Container} from "react-bootstrap";

export default function App() {
  return (
    <>
      <Navbar/>
      <Container>
        <HeroBanner/>
        <Features/>
  
        <Screenshots/>
        <Commitment/>
      </Container>
      <Download/>
      <Footer />
    </>
  );
}
