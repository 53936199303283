import React from "react";
import {Container, Row, Col} from "react-bootstrap";

function Screenshots() {
  return (
    <section id="commitments">
      <div className="container" style={{minWidth: '90%'}}>
        <Container className="section-text commitment-section">
          <div className="section-text__title commitment-title">Our Commitments: The <span
            className={'trade-mark'}>Matty</span> Five
          </div>
          <Row>
            <Col xs={12} md={6}>
              <div className="section-text__title commitment-subtitle">Zero Commissions on Trades</div>
              <div className={'commitment-row'}>
                <img src={'./images/commissions.png'} className={'commission-image'}/>
                <div className={'commitment-row-desr'}><span className={'trade-mark'}>Matty</span> offers
                  commission-free trading for everyone. No matter how much you plan to invest, you will never be charged
                  account minimums or commissions in order to buy or sell stocks… ever.
                </div>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="section-text__title commitment-subtitle">Extra Protection</div>
              <div className={'commitment-row'}>
                <img src={'./images/protection.png'} className={'commission-image'}/>
                <div className={'commitment-row-desr'}>
                  <span className={'trade-mark'}>Matty</span> operates based on user-determined portfolio risk
                  thresholds, so you never have to worry about stomaching large swings
                  in the value of your investments if you don’t choose to.
                </div>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="section-text__title commitment-subtitle">World Class Technology</div>
              <div className={'commitment-row'}>
                <img src={'./images/technology.png'} className={'commission-image'}/>
                <div className="commitment-row-desr"><span className={'trade-mark'}>Matty</span> delivers lifestyle-centric(Patent Pending) and goal-based investment advice. Our algorithms monitor each stock price within your portfolio and place the buy and sell orders when the defined conditions are met.
                </div>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="section-text__title commitment-subtitle">Full Security</div>
              <div className={'commitment-row'}>
                <img src={'./images/security.png'} className={'commission-image'}/>
                <div className="commitment-row-desr">
                  The safety of your account is our highest priority. <span className={'trade-mark'}>Matty</span> is registered with and regulated by the Securities and Exchange Commission.
                </div>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="section-text__title commitment-subtitle">Full Transparency</div>
              <div className={'commitment-row'}>
                <img src={'./images/transparency.png'} className={'commission-image'}/>
                <div className="commitment-row-desr">
                  First and foremost, <span className={'trade-mark'}>Matty</span> is here to help YOU succeed by offering smart money management tools. Our business model is fully transparent and there are no hidden agendas or conditions.
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}

export default Screenshots;
